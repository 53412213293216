.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.MuiAutocomplete-root {
  z-index: 1;
}

.modal-content p {
  margin-bottom: 10px;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #00ADB5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #009999;
}

.modal.modal-background-disabled {
  pointer-events: none;
}

.blur-effect {
  filter: blur(30px);
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stack-item {
  color: white;
  background-color: #222831;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.5s ease;
}

.stack-item.animate-in {
  animation: fadeInUp 1s ease forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-in {
  animation: fadeIn 0.5s ease-out forwards;
}


.stack-item:hover {
  transform: translateY(-5px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




.stack-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.circles-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  margin-right: 0; /* Remove margin between circle containers */
}


.circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #393E46;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.green-circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #393E46;
}

.card-container {
  background-color: #222831;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.card-content {
  color: #000;
}


.mobile-only-button {
  display: none; 
  background-color: #00ADB5; 
  color: #fff; 
  border: none;
  padding: 10px 20px; 
  border-radius: 5px; 
  cursor: pointer; 
}


@media (max-width: 768px) {
  .mobile-only-button {
    display: block;
    margin-top: 10px;
  }


  .stack-item {
    width: 100%; 
    padding: 10px; 
    margin-bottom: 10px; 
  }

  .stack-title, .circle-text, .circle {
    font-size: 14px; 
  }
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #393E46;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  
  .green-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

/* App.css */
.App {
  position: relative;
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 62, 70, 1); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the spinner is on top of other elements */
}
